import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getOrderList,
  createCancelOrder,
} from "../../../services/Order.service";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { PATH } from "../../../redux/constants/Constants";

export default function Orders(props) {
  const actTab = props.activeTab;
  const customerId = useSelector((state) => state?.user?.user?.token?.id);
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const currencySymbol = useSelector(
    (state) => state.status.restaurant.currency_symbol
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [orderList, setOrderList] = useState([]);
  const [filteredOrderList, setFilteredOrderList] = useState([]);
  const [orderDetailsData, setOrderDetailsData] = useState(null);

  const {
    register,
    handleSubmit: handleSubmitCancel,
    formState: { errors },
  } = useForm();

  const [filters, setFilters] = useState({
    status: [],
    time: [],
  });

  const [paymentSuccessful, setPaymentSuccessful] = useState(
    location?.state?.paymentSuccessful
  );

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (location?.state?.paymentSuccessful) {
      setPaymentSuccessful(location?.state?.paymentSuccessful);
      paymentSuccessful &&
        toast.success(
          <React.Fragment>
            Order placed successfully!
            <br />
            Thank you for shopping with us.
          </React.Fragment>,
          {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "orderList",
          }
        );
      setPaymentSuccessful(false);
    }

    fetchAPIs();
  }, []);

  useEffect(() => {
    applyFilters(filters);
  }, [filters, orderList]); // Apply filters whenever filters or orderList change

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters }; // Shallow copy of filters object
      if (!newFilters[type]) {
        newFilters[type] = []; // Initialize the array if it doesn't exist
      }
      const index = newFilters[type].indexOf(value);
      if (index === -1) {
        newFilters[type] = [...newFilters[type], value]; // Add value if not already present
      } else {
        newFilters[type] = newFilters[type].filter((v) => v !== value); // Remove value if already present
      }
      return newFilters;
    });
  };

  const applyFilters = (filters) => {
    let filteredOrders = orderList;

    if (filters.status.length > 0) {
      filteredOrders = filteredOrders.filter((order) =>
        filters.status.includes(order.status)
      );
    }

    if (filters.time.length > 0) {
      const now = new Date();
      filteredOrders = filteredOrders.filter((order) => {
        const orderDate = new Date(order.order.created_at);
        if (filters.time.includes("Last 30 days")) {
          const last30Days = new Date(now);
          last30Days.setDate(now.getDate() - 30);
          return orderDate >= last30Days;
        }
        if (filters.time.includes("Last 10 days")) {
          const last10Days = new Date(now);
          last10Days.setDate(now.getDate() - 10);
          return orderDate >= last10Days;
        }
        if (filters.time.includes(orderDate.getFullYear().toString())) {
          return true;
        }
        if (
          filters.time.includes("Older") &&
          orderDate.getFullYear() < now.getFullYear() - 4
        ) {
          return true;
        }
        return false;
      });
    }

    setFilteredOrderList(filteredOrders);
  };

  const fetchAPIs = () => {
    getOrderList(token, customerId)
      .then((res) => {
        // console.log(res?.data);
        setOrderList(res?.data);
        setFilteredOrderList(res?.data); // Initialize filteredOrderList with all orders on fetch
      })
      .catch((error) => {
        console.error("Error fetching order list:", error);
      });
  };

  const handleOrderDetails = (item) => {
    // console.log(item);
    navigate(`/my-account/order-details`, {
      state: {
        orderDetails: item,
      },
    });
  };

  const handleCopy = (orderId) => {
    const textToCopy = document.getElementById(
      `textToCopy-${orderId}`
    ).innerText;
    const copyMessage = document.getElementById(`copyMessage-${orderId}`);

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        // Show the "Copied!" message
        copyMessage.classList.remove("hidden");
        copyMessage.classList.add("visible");

        // Hide the "Copied!" message after 2 seconds
        setTimeout(() => {
          copyMessage.classList.remove("visible");
          copyMessage.classList.add("hidden");
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  // const cancelOrder = async (item) => {
  //   const data = {
  //     is_delivery_cancel: false,
  //     payment_id: orderDetailsData.payment_id,
  //     reason_for_cancellation: item.remark,
  //   };

  //   console.log(data);
  //   console.log(item);

  //   createCancelOrder(token, data).then((res) => {
  //     console.log(res?.data);
  //     toast.error("Order Canceled...!", {
  //       autoClose: 1500,
  //       style: {
  //         backgroundColor: "black",
  //         color: "white",
  //       },
  //     });
  //     document.getElementById("closeCancelModal").click();
  //     // setTimeout(() => {
  //     //   navigate("/my-account/orders");
  //     // }, 1500);
  //     fetchAPIs();
  //   });
  // };

  const cancelOrder = async (item) => {
    const data = {
      is_delivery_cancel: false,
      payment_id: orderDetailsData.payment_id,
      reason_for_cancellation: item.remark,
    };

    createCancelOrder(token, data)
      .then((res) => {
        // console.log(res?.data);

        toast.error(<React.Fragment>Order has been canceled!<br />For refund: Please check your mail.</React.Fragment>, {
          autoClose: 3000,
          style: {
            backgroundColor: "black",
            color: "white",
          },
          containerId: "orderList",
        });
        document.getElementById("closeCancelModal").click();
        // setTimeout(() => {
        //   navigate("/my-account/orders");
        // }, 1500);
        fetchAPIs();
      })
      .catch((error) => {
        // console.log("Error canceling order:", error);
      });
  };

  const clearFilters = () => {
    setFilters({
      status: [],
      time: [],
    });
    // setIsFilterVisible((prev) => !prev);
  };
  // console.log(filters);

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prev) => !prev);
  };

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="orderList"
      />

      {/* Cancel Order Modal */}
      <div
        className="modal fade mt-5 ms-2"
        id="cancelOrderModal"
        tabIndex="-1"
        aria-labelledby="cancelOrderModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5">
          <div className="modal-body">
            <div>
              <div className="login-reg-form-wrap">
                <div className="d-flex justify-content-between mb-0">
                  <h2>Warning</h2>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="closeCancelModal"
                  ></button>
                </div>

                <form
                  action="#"
                  method="post"
                  onSubmit={handleSubmitCancel(cancelOrder)}
                >
                  <div className="">
                    <label className="mb-3">
                      <b> Are you sure you want to Cancel this Order?</b>
                    </label>{" "}
                  </div>
                  <label>Add Remark</label>{" "}
                  <i className="text-danger">
                    <b>*</b>
                  </i>
                  <div className="single-input-item mb-5 mt-1">
                    <input
                      className="mt-2"
                      type="text"
                      placeholder="Enter Remark"
                      {...register("remark", {
                        required: "Enter Remark",
                      })}
                      maxLength={500}
                    />
                    {errors.remark && (
                      <p className="error">{errors.remark.message}</p>
                    )}
                  </div>
                  <div className="single-input-item">
                    <button className="sqr-btn w-100" type="submit">
                      Cancel Order
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end */}

      <div className="tab-content" id="myaccountContent">
        {/* <!-- Single Tab Content Start --> */}
        <div
          className={`tab-pane fade ${actTab === "ordersTab" || actTab === "orderDetailsTab"
              ? "show active"
              : ""
            }`}
          id="orders"
          role="tabpanel"
          aria-labelledby="orders-tab"
        >
          {orderList.length > 0 ? (
            <div className="myaccount-content">
              <div className="d-flex justify-content-between">
                <h3 style={{ border: "none" }}>Orders</h3>
                <div className="product-btn filter-order">
                  <Link
                    to="javascript:void(0)"
                    onClick={toggleFilterVisibility}
                  >
                    Filter{" "}
                    {isFilterVisible ? (
                      <img
                        src={`${PATH}/assets/img/bamboosa/close.png`}
                        alt="filter"
                        className="ms-2"
                        style={{ width: "20px" }}
                      />
                    ) : (
                      <img
                        src={`${PATH}/assets/img/bamboosa/setting.png`}
                        alt="filter"
                        className="ms-2"
                        style={{ width: "20px" }}
                      />
                    )}
                  </Link>
                </div>
              </div>

              {/* filter order list */}
              {isFilterVisible && (
                <div className="filters" style={{ height: "auto" }}>
                  <div className="filters-wrapper">
                    <div className="filters-container">
                      <h6 className="mb-2 fw-bold p-1">Order Status</h6>
                      <div className="filter-group mb-2">
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id="orderPlaced"
                            value="ORDER_PLACED"
                            checked={filters.status.includes("ORDER_PLACED")}
                            onChange={() =>
                              handleFilterChange("status", "ORDER_PLACED")
                            }
                            hidden
                          />
                          <label
                            htmlFor="orderPlaced"
                            className={`oval-label ${filters.status.includes("ORDER_PLACED")
                                ? "selected"
                                : ""
                              }`}
                          >
                            Order Placed
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id="returned"
                            value="CONFIRMED"
                            checked={filters.status.includes("CONFIRMED")}
                            onChange={() =>
                              handleFilterChange("status", "CONFIRMED")
                            }
                            hidden
                          />
                          <label
                            htmlFor="returned"
                            className={`oval-label ${filters.status.includes("CONFIRMED")
                                ? "selected"
                                : ""
                              }`}
                          >
                            Confirmed
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id="delivered"
                            value="DELIVERED"
                            checked={filters.status.includes("DELIVERED")}
                            onChange={() =>
                              handleFilterChange("status", "DELIVERED")
                            }
                            hidden
                          />
                          <label
                            htmlFor="delivered"
                            className={`oval-label ${filters.status.includes("DELIVERED")
                                ? "selected"
                                : ""
                              }`}
                          >
                            Delivered
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id="Canceled"
                            value="Canceled"
                            checked={filters.status.includes("Canceled")}
                            onChange={() =>
                              handleFilterChange("status", "Canceled")
                            }
                            hidden
                          />
                          <label
                            htmlFor="Canceled"
                            className={`oval-label ${filters.status.includes("Canceled")
                                ? "selected"
                                : ""
                              }`}
                          >
                            Canceled
                          </label>
                        </div>
                      </div>
                      <h6 className="mb-2 fw-bold p-1">Order Time</h6>
                      <div className="filter-group">
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id="last30Days"
                            value="Last 30 days"
                            checked={filters.time.includes("Last 30 days")}
                            onChange={() =>
                              handleFilterChange("time", "Last 30 days")
                            }
                            hidden
                          />
                          <label
                            htmlFor="last30Days"
                            className={`oval-label ${filters.time.includes("Last 30 days")
                                ? "selected"
                                : ""
                              }`}
                          >
                            Last 30 days
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id={currentYear - 1}
                            value={currentYear - 1}
                            checked={filters.time.includes(currentYear - 1)}
                            onChange={() => handleFilterChange("time", currentYear - 1)}
                            hidden
                          />
                          <label
                            htmlFor={currentYear - 1}
                            className={`oval-label ${filters.time.includes(currentYear - 1) ? "selected" : ""
                              }`}
                          >
                            {currentYear - 1}
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id={currentYear - 2}
                            value={currentYear - 2}
                            checked={filters.time.includes(currentYear - 2)}
                            onChange={() => handleFilterChange("time", currentYear - 2)}
                            hidden
                          />
                          <label
                            htmlFor={currentYear - 2}
                            className={`oval-label ${filters.time.includes(currentYear - 2) ? "selected" : ""
                              }`}
                          >
                            {currentYear - 2}
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id={currentYear - 3}
                            value={currentYear - 3}
                            checked={filters.time.includes(currentYear - 3)}
                            onChange={() => handleFilterChange("time", currentYear - 3)}
                            hidden
                          />
                          <label
                            htmlFor={currentYear - 3}
                            className={`oval-label ${filters.time.includes(currentYear - 3) ? "selected" : ""
                              }`}
                          >
                            {currentYear - 3}
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id={currentYear - 4}
                            value={currentYear - 4}
                            checked={filters.time.includes(currentYear - 4)}
                            onChange={() => handleFilterChange("time", currentYear - 4)}
                            hidden
                          />
                          <label
                            htmlFor={currentYear - 4}
                            className={`oval-label ${filters.time.includes(currentYear - 4) ? "selected" : ""
                              }`}
                          >
                            {currentYear - 4}
                          </label>
                        </div>
                        <div className="checkbox-row">
                          <input
                            type="checkbox"
                            id="older"
                            value="Older"
                            checked={filters.time.includes("Older")}
                            onChange={() => handleFilterChange("time", "Older")}
                            hidden
                          />
                          <label
                            htmlFor="older"
                            className={`oval-label ${filters.time.includes("Older") ? "selected" : ""
                              }`}
                          >
                            Older
                          </label>
                        </div>
                      </div>
                      {filters.status.length > 0 || filters.time.length > 0 ? (
                        <div className="d-flex justify-content-end">
                          <button
                            onClick={clearFilters}
                            className="clear-filters-button mt-2 me-2 btn-success"
                          >
                            Clear Filters
                          </button>
                          <button
                            className="clear-filters-button mt-2 me-2 btn-success"
                            style={{ color: "#333c4a" }}
                            onClick={toggleFilterVisibility}
                          >
                            Done
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex justify-content-end">
                          <button
                            onClick={clearFilters}
                            className="clear-filters-button mt-2 float-end me-2 btn-success1"
                          >
                            Clear Filters
                          </button>
                          <button
                            className="clear-filters-button mt-2 me-2 btn-success"
                            style={{ color: "red" }}
                            onClick={toggleFilterVisibility}
                          >
                            Close
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {/* end */}

              <h3></h3>
              <div>
                <div className="myaccount-table table-responsive text-center">
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Order ID</th>
                        <th>Status</th>
                        <th>Payment Method</th>
                        <th>Total</th>
                        <th>Action</th>
                        <th>Cancel Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredOrderList.map((item, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>
                            {new Date(item.created_at).toLocaleDateString(
                              "en-US",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </td>
                          <td>
                            #
                            <strong id={`textToCopy-${item.order.order_id}`}>
                              {item.order.order_id}
                            </strong>{" "}
                            <img
                              id={`copyButton-${item.order.order_id}`}
                              src={`${PATH}/assets/img/bamboosa/copy.png`}
                              className="width-img"
                              alt="copy"
                              onClick={() => handleCopy(item.order.order_id)}
                            />{" "}
                            <span
                              id={`copyMessage-${item.order.order_id}`}
                              className="hidden"
                            >
                              Copied!
                            </span>
                          </td>
                          <td>
                            {item.status.replaceAll("_", " ").toUpperCase()}
                          </td>
                          <td>{item.payment_method}</td>
                          <td>
                            {currencySymbol === "₹" ? "₹" : "$"}
                            {(
                              item.order.subtotal - item.order.discount
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td onClick={() => handleOrderDetails(item)}>
                            <Link
                              className="check-btn sqr-btn view"
                              to="javascript:void(0)"
                            >
                              View
                            </Link>
                          </td>
                          <td onClick={() => setOrderDetailsData(item)}>
                            {item.status === "ORDER_PLACED" ? (
                              <i
                                className="fa fa-trash popup"
                                data-bs-toggle="modal"
                                data-bs-target="#cancelOrderModal"
                              ></i>
                            ) : (
                              <i className="fa fa-trash disabled-trash"></i>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <div className="myaccount-content d-flex justify-content-center">
              <img
                style={{ width: "40%" }}
                src={`${PATH}/assets/img/bamboosa/no-order.png`}
                alt="empty-order-lis"
              />
            </div>
          )}
        </div>
        {/* <!-- Single Tab Content End --> */}
      </div>
    </div>
  );
}
