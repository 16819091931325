// import React, { useEffect, useState, useRef } from "react";
// import confetti from "canvas-confetti";
// import { PATH } from "../../redux/constants/Constants";
// import { getInaugration, inaugration } from "../../services/UserLogin.service";
// import { useSelector } from "react-redux";

// const Popup = ({ onClose }) => {
//   const token = useSelector((state) => state.status.restaurant.Token);

//   const [showFireworks, setShowFireworks] = useState(false);
//   const audioRef = useRef(null); // Ref to keep track of the audio object
//   const [inaugrationData, setInaugrationData] = useState(null);

//   const handleInaugration = () => {
//     const data = {
//       status: true,
//     };
//     setShowFireworks(true);

//     inaugration(token, data).then((res) => {
//       console.log(res);
//     });
//   };

//   useEffect(() => {
//     getInaugration(token).then((res) => {
//       console.log(res.data);
//       setInaugrationData(res.data);
//     });

//     // document.getElementById("fireworks") && document.getElementById("fireworks").click();

//     console.log(document.getElementById("fireworks"));
//     if (showFireworks) {
//       // Play music when fireworks start
//       // const audio = new Audio(`${PATH}/assets/img/bamboosa/fireworks.mp3`);
//       const audio = new Audio(`${PATH}/assets/img/bamboosa/bamboosa-audio.mp3`);

//       audioRef.current = audio; // Store the audio reference

//       // Attempt to play the audio
//       audio
//         .play()
//         .catch((error) => console.error("Audio playback failed:", error));

//       // Fire crackers (confetti) and long ribbons
//       const duration = 16 * 1000; // 16 seconds
//       const animationEnd = Date.now() + duration;
//       const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

//       const randomInRange = (min, max) => Math.random() * (max - min) + min;

//       const interval = setInterval(() => {
//         const timeLeft = animationEnd - Date.now();

//         if (timeLeft <= 0) {
//           return clearInterval(interval);
//         }

//         const particleCount = 100 * (timeLeft / duration);

//         // Confetti (crackers) effect
//         confetti(
//           Object.assign({}, defaults, {
//             particleCount,
//             origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//           })
//         );
//         confetti(
//           Object.assign({}, defaults, {
//             particleCount,
//             origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//           })
//         );

//         // Long ribbons effect
//         confetti({
//           particleCount: 5,
//           angle: 60,
//           spread: 55,
//           origin: { x: 0, y: 0.5 },
//           colors: ["#bb0000", "#ffffff"],
//           shapes: ["square"],
//           scalar: 1.2,
//           startVelocity: 50,
//           ticks: 200,
//         });

//         confetti({
//           particleCount: 5,
//           angle: 120,
//           spread: 55,
//           origin: { x: 1, y: 0.5 },
//           colors: ["#bb0000", "#ffffff"],
//           shapes: ["square"],
//           scalar: 1.2,
//           startVelocity: 50,
//           ticks: 200,
//         });
//       }, 250);

//       // Clean up the interval when the component is unmounted
//       return () => {
//         clearInterval(interval);
//         if (audioRef.current) {
//           audioRef.current.pause(); // Pause the audio if it's playing
//           audioRef.current.currentTime = 0; // Reset audio to the beginning
//         }
//       };
//     }
//   }, [showFireworks]);

//   const startFireworks = () => {
//     setShowFireworks(true);
//   };

//   const handleClose = () => {
//     if (audioRef.current) {
//       audioRef.current.pause(); // Pause the audio when closing the popup
//       audioRef.current.currentTime = 0; // Reset audio to the beginning
//     }
//     onClose();
//   };

//   return (
//     <div style={styles.overlay}>
//       {/* <audio autoplay loop  id="playAudio">
//     <source src={`${PATH}/assets/img/bamboosa/fireworks.mp3`} />
// </audio> */}
//       <div style={styles.popup}>
//         {/* <h2>Welcome to Our Website!</h2> */}
//         {/* <img src={`${PATH}/assets/img/bamboosa/popup.png`} alt='popup' /> */}
//         <video
//           playsInline
//           autoPlay
//           loop
//           muted
//           // poster="https://storage.googleapis.com/restaurant60-prod-media/homepagePoster.webp"
//         >
//           <source
//             src={`${PATH}/assets/img/bamboosa/bamboosa-video.mp4`}
//             type="video/mp4"
//           />
//         </video>
//         {/* <p>Thanks for visiting. We hope you have a great experience!</p> */}
//         <p></p>
//         {!showFireworks && (
//           <button
//             id="fireworks"
//             onClick={() => handleInaugration()}
//             style={{ color: "red" }}
//           >
//             Start Celebration!
//           </button>
//         )}
//         {showFireworks && (
//           <button onClick={handleClose} style={{ color: "red" }}>
//             Close
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",

//   },
//   popup: {
//     // backgroundColor: "#fff",
//     backgroundColor: "#000",
//     padding: "10px",
//     borderRadius: "8px",
//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
//     textAlign: "center",
//     // width: "50%",
//   },
// };

// export default Popup;

// import React, { useEffect, useState, useRef } from "react";
// import confetti from "canvas-confetti";
// import { PATH } from "../../redux/constants/Constants";
// import { getInaugration, inaugration } from "../../services/UserLogin.service";
// import { useSelector } from "react-redux";

// const Popup = ({ onClose }) => {
//   const token = useSelector((state) => state.status.restaurant.Token);
//   const [showFireworks, setShowFireworks] = useState(false);
//   const audioRef = useRef(null); // Ref to keep track of the audio object
//   const [inaugrationData, setInaugrationData] = useState(null);

//   const handleInaugration = () => {
//     const data = {
//       status: true,
//     };
//     setShowFireworks(true);

//     inaugration(token, data).then((res) => {
//       console.log(res);
//     });
//   };

//   useEffect(() => {
//     getInaugration(token).then((res) => {
//       console.log(res.data);
//       setInaugrationData(res.data);
//     });

//     if (showFireworks) {
//       // Play music when fireworks start
//       const audio = new Audio(`${PATH}/assets/img/bamboosa/bamboosa-audio.mp3`);
//       audioRef.current = audio; // Store the audio reference

//       // Attempt to play the audio
//       audio
//         .play()
//         .catch((error) => console.error("Audio playback failed:", error));

//       // Fire crackers (confetti) and long ribbons
//       const duration = 16 * 1000; // 16 seconds
//       const animationEnd = Date.now() + duration;
//       const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

//       const randomInRange = (min, max) => Math.random() * (max - min) + min;

//       const interval = setInterval(() => {
//         const timeLeft = animationEnd - Date.now();

//         if (timeLeft <= 0) {
//           return clearInterval(interval);
//         }

//         const particleCount = 100 * (timeLeft / duration);

//         // Confetti (crackers) effect
//         confetti(
//           Object.assign({}, defaults, {
//             particleCount,
//             origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//           })
//         );
//         confetti(
//           Object.assign({}, defaults, {
//             particleCount,
//             origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//           })
//         );

//         // // Long ribbons effect
//         // confetti({
//         //   particleCount: 5,
//         //   angle: 60,
//         //   spread: 55,
//         //   origin: { x: 0, y: 0.5 },
//         //   colors: ["#bb0000", "#ffffff"],
//         //   shapes: ["square"],
//         //   scalar: 1.2,
//         //   startVelocity: 50,
//         //   ticks: 200,
//         // });

//         // Long ribbons effect
//         confetti({
//           particleCount: 10, // Increase particle count to make ribbons more visible
//           angle: 60,
//           spread: 55,
//           origin: { x: 0, y: 0.5 },
//           colors: ["#bb0000", "#ffffff"],
//           shapes: ["rect"], // Use rectangle shape
//           scalar: 2.5, // Increase scalar to make ribbons longer
//           startVelocity: 30,
//           ticks: 200,
//           gravity: 0.5, // Adjust gravity for a slower fall
//           drift: 0.5, // Add drift for a more natural look
//           // Custom draw function to make ribbons look longer
//           drawShape: (ctx) => {
//             ctx.beginPath();
//             ctx.moveTo(-10, 0);
//             ctx.lineTo(10, 0);
//             ctx.lineTo(10, 20);
//             ctx.lineTo(-10, 20);
//             ctx.closePath();
//             ctx.fill();
//           },
//         });

//         confetti({
//           particleCount: 10,
//           angle: 120,
//           spread: 55,
//           origin: { x: 1, y: 0.5 },
//           colors: ["#bb0000", "#ffffff"],
//           shapes: ["rect"],
//           scalar: 2.5,
//           startVelocity: 30,
//           ticks: 200,
//           gravity: 0.5,
//           drift: -0.5,
//           drawShape: (ctx) => {
//             ctx.beginPath();
//             ctx.moveTo(-10, 0);
//             ctx.lineTo(10, 0);
//             ctx.lineTo(10, 20);
//             ctx.lineTo(-10, 20);
//             ctx.closePath();
//             ctx.fill();
//           },
//         });

//         confetti({
//           particleCount: 5,
//           angle: 120,
//           spread: 55,
//           origin: { x: 1, y: 0.5 },
//           colors: ["#bb0000", "#ffffff"],
//           shapes: ["square"],
//           scalar: 1.2,
//           startVelocity: 50,
//           ticks: 200,
//         });
//       }, 250);

//       // Clean up the interval when the component is unmounted
//       return () => {
//         clearInterval(interval);
//         if (audioRef.current) {
//           audioRef.current.pause(); // Pause the audio if it's playing
//           audioRef.current.currentTime = 0; // Reset audio to the beginning
//         }
//       };
//     }
//   }, [showFireworks]);

//   // Auto-close popup after 20 seconds
//   useEffect(() => {
//     if (showFireworks) {
//       const timer = setTimeout(handleClose, 20000); // 20 seconds
//       return () => clearTimeout(timer);
//     }
//   }, [showFireworks]);

//   const startFireworks = () => {
//     setShowFireworks(true);
//   };

//   const handleClose = () => {
//     if (audioRef.current) {
//       audioRef.current.pause(); // Pause the audio when closing the popup
//       audioRef.current.currentTime = 0; // Reset audio to the beginning
//     }
//     onClose();
//   };

//   return (
//     <div style={styles.overlay}>
//       <div style={styles.popup}>
//         <video playsInline autoPlay loop muted>
//           <source
//             src={`${PATH}/assets/img/bamboosa/bamboosa-video.mp4`}
//             type="video/mp4"
//           />
//         </video>
//         <p></p>
//         {!showFireworks && (
//           <button
//             id="fireworks"
//             onClick={handleInaugration}
//             style={{ color: "red" }}
//           >
//             Start Celebration!
//           </button>
//         )}
//         {/* {showFireworks && (
//           <button onClick={handleClose} style={{ color: "red" }}>
//             Close
//           </button>
//         )} */}
//       </div>
//     </div>
//   );
// };

// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   popup: {
//     backgroundColor: "#000",
//     padding: "10px",
//     borderRadius: "8px",
//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
//     textAlign: "center",
//   },
// };

// export default Popup;


// import React, { useEffect, useState, useRef } from "react";
// import confetti from "canvas-confetti";
// import { PATH } from "../../redux/constants/Constants";
// import { getInaugration, inaugration } from "../../services/UserLogin.service";
// import { useSelector } from "react-redux";

// const Popup = ({ onClose }) => {
//   const token = useSelector((state) => state.status.restaurant.Token);
//   const [showFireworks, setShowFireworks] = useState(false);
//   const audioRef = useRef(null); // Ref to keep track of the audio object
//   const confettiRef = useRef(null); // Ref to keep track of the confetti canvas
//   const [inaugrationData, setInaugrationData] = useState(null);

//   const handleInaugration = () => {
//     const data = { status: true };
//     setShowFireworks(true);

//     inaugration(token, data).then((res) => {
//       console.log(res);
//     });
//   };

//   useEffect(() => {
//     getInaugration(token).then((res) => {
//       console.log(res.data);
//       setInaugrationData(res.data);
//     });

//     if (showFireworks) {
//       // Play music when fireworks start
//       const audio = new Audio(`${PATH}/assets/img/bamboosa/bamboosa-audio.mp3`);
//       audioRef.current = audio; // Store the audio reference

//       // Attempt to play the audio
//       audio.play().catch((error) => console.error("Audio playback failed:", error));

//       // Fire crackers (confetti) and long ribbons
//       const confettiInstance = confetti.create(confettiRef.current, {
//         resize: true,
//         useWorker: true,
//       });

//       const duration = 15 * 1000; // 16 seconds
//       const animationEnd = Date.now() + duration;
//       const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

//       const randomInRange = (min, max) => Math.random() * (max - min) + min;

//       const interval = setInterval(() => {
//         const timeLeft = animationEnd - Date.now();

//         if (timeLeft <= 0) {
//           clearInterval(interval);
//           return;
//         }

//         const particleCount = 80 * (timeLeft / duration);

//         // Confetti (crackers) effect
//         confettiInstance({
//           ...defaults,
//           particleCount,
//           origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//         });
//         confettiInstance({
//           ...defaults,
//           particleCount,
//           origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//         });

//         // Long ribbons effect
//         const drawRibbon = (originX, originY, angle, spread, drift, gravity) => {
//           confettiInstance({
//             particleCount: 10,
//             angle: angle,
//             spread: spread,
//             origin: { x: originX, y: originY },
//             colors: ["#bb0000", "#ffffff"],
//             shapes: ["rect"],
//             scalar: 1.5,
//             startVelocity: 30,
//             ticks: 200,
//             gravity: gravity,
//             drift: drift,
//             disableForReducedMotion: true,
//           });
//         };

//         drawRibbon(0, 0.5, 60, 55, 0.5, 0.5);
//         drawRibbon(1, 0.5, 120, 55, -0.5, 0.5);
//       }, 250);

//       // Clean up the interval when the component is unmounted
//       return () => {
//         clearInterval(interval);
//         if (audioRef.current) {
//           audioRef.current.pause(); // Pause the audio if it's playing
//           audioRef.current.currentTime = 0; // Reset audio to the beginning
//         }
//         confettiInstance.reset();
//       };
//     }
//   }, [showFireworks]);

//   // Auto-close popup after 20 seconds
//   useEffect(() => {
//     if (showFireworks) {
//       const timer = setTimeout(handleClose, 17000); // 17 seconds
//       return () => clearTimeout(timer);
//     }
//   }, [showFireworks]);

//   const startFireworks = () => {
//     setShowFireworks(true);
//   };

//   const handleClose = () => {
//     if (audioRef.current) {
//       audioRef.current.pause(); // Pause the audio when closing the popup
//       audioRef.current.currentTime = 0; // Reset audio to the beginning
//     }
//     onClose();
//   };

//   return (
//     <div style={styles.overlay}>
//       <div style={styles.popup}>
//         <video playsInline autoPlay loop muted>
//           <source src={`${PATH}/assets/img/bamboosa/bamboosa-video.mp4`} type="video/mp4" />
//         </video>
//         <p></p>
//         {!showFireworks && (
//           <button id="fireworks" onClick={handleInaugration} style={{ color: "red" }}>
//             Start Celebration!
//           </button>
//         )}
//         <canvas ref={confettiRef} style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", pointerEvents: "none" }} />
//       </div>
//     </div>
//   );
// };

// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   popup: {
//     backgroundColor: "#000",
//     padding: "10px",
//     borderRadius: "8px",
//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
//     textAlign: "center",
//   },
// };

// export default Popup;

// import React, { useEffect, useState, useRef } from "react";
// import confetti from "canvas-confetti";
// import { PATH } from "../../redux/constants/Constants";
// import { getInaugration, inaugration } from "../../services/UserLogin.service";
// import { useSelector } from "react-redux";

// const Popup = ({ onClose }) => {
//   const token = useSelector((state) => state.status.restaurant.Token);
//   const [showFireworks, setShowFireworks] = useState(false);
//   const audioRef = useRef(null); // Ref to keep track of the audio object
//   const confettiRef = useRef(null); // Ref to keep track of the confetti canvas
//   const [inaugrationData, setInaugrationData] = useState(null);

//   const handleInaugration = () => {
//     const data = { status: false };
//     setShowFireworks(true);

//     inaugration(token, data).then((res) => {
//       console.log(res);
//     });
//   };

//   useEffect(() => {
//     getInaugration(token).then((res) => {
//       console.log(res.data);
//       setInaugrationData(res.data);
//     });

//     if (showFireworks) {
//       // Play music when fireworks start
//     //  const audio = new Audio(`${PATH}/assets/img/bamboosa/fireworks.mp3`);

//       const audio = new Audio(`${PATH}/assets/img/bamboosa/bamboosa-audio.mp3`);
//       audioRef.current = audio; // Store the audio reference

//       // Attempt to play the audio
//       audio.play().catch((error) => console.error("Audio playback failed:", error));

//       // Fire crackers (confetti) and long ribbons
//       const confettiInstance = confetti.create(confettiRef.current, {
//         resize: true,
//         useWorker: true,
//       });

//       const duration = 15 * 1000; // 16 seconds
//       const animationEnd = Date.now() + duration;
//       const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

//       const randomInRange = (min, max) => Math.random() * (max - min) + min;

//       const interval = setInterval(() => {
//         const timeLeft = animationEnd - Date.now();

//         if (timeLeft <= 0) {
//           clearInterval(interval);
//           return;
//         }

//         const particleCount = 80 * (timeLeft / duration);

//         // Confetti (crackers) effect
//         confettiInstance({
//           ...defaults,
//           particleCount,
//           origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
//         });
//         confettiInstance({
//           ...defaults,
//           particleCount,
//           origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
//         });

//         // Long ribbons effect
//         const drawRibbon = (originX, originY, angle, spread, drift, gravity) => {
//           confettiInstance({
//             particleCount: 10,
//             angle: angle,
//             spread: spread,
//             origin: { x: originX, y: originY },
//             colors: ["#bb0000", "#ffffff"],
//             shapes: ["rect"],
//             scalar: 1.5,
//             startVelocity: 30,
//             ticks: 200,
//             gravity: gravity,
//             drift: drift,
//             disableForReducedMotion: true,
//           });
//         };

//         drawRibbon(0, 0.5, 60, 55, 0.5, 0.5);
//         drawRibbon(1, 0.5, 120, 55, -0.5, 0.5);
//       }, 250);

//       // Clean up the interval when the component is unmounted
//       return () => {
//         clearInterval(interval);
//         if (audioRef.current) {
//           audioRef.current.pause(); // Pause the audio if it's playing
//           audioRef.current.currentTime = 0; // Reset audio to the beginning
//         }
//         confettiInstance.reset();
//       };
//     }
//   }, [showFireworks]);

//   // Auto-close popup after 20 seconds
//   useEffect(() => {
//     if (showFireworks) {
//       const timer = setTimeout(handleClose, 17000); // 17 seconds
//       return () => clearTimeout(timer);
//     }
//   }, [showFireworks]);

//   const startFireworks = () => {
//     setShowFireworks(true);
//   };

//   const handleClose = () => {
//     if (audioRef.current) {
//       audioRef.current.pause(); // Pause the audio when closing the popup
//       audioRef.current.currentTime = 0; // Reset audio to the beginning
//     }
//     onClose();
//   };

//   return (
//     <div style={styles.overlay}>
//       <div style={styles.popup}>
//         <video playsInline autoPlay loop muted>
//           <source src={`${PATH}/assets/img/bamboosa/bamboosa-video.mp4`} type="video/mp4" />
//         </video>
//         {/* <p></p> */}
//         {!showFireworks && (
//           <div style={styles.buttonContainer}>
//             <button
//               id="fireworks"
//               onClick={handleInaugration}
//               style={styles.ribbonButton}
//             >
//               Start Celebration!
//             </button>
//           </div>
//         )}
//         <canvas
//           ref={confettiRef}
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             height: "100%",
//             pointerEvents: "none",
//           }}
//         />
//       </div>
//     </div>
//   );
// };

// const styles = {
//   overlay: {
//     position: "fixed",
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,
//     backgroundColor: "rgba(0, 0, 0, 0.5)",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//   },
//   popup: {
//     backgroundColor: "#000",
//     padding: "10px",
//     borderRadius: "5px",
//     boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
//     textAlign: "center",
//   },
//   buttonContainer: {
//     position: "relative",
//     // display: "inline-block",
//   },
//   ribbonButton: {
//     position: "absolute",
//     bottom: 15,
//     right: 410,
//     // overflow: "hidden",
//     color: "white",
//     backgroundColor: "red",
//     border: "none",
//     padding: "10px 20px",
//     fontSize: "18px",
//     cursor: "pointer",
//     zIndex: 999999999999999,
//     outline: "none",
//   },
// };

// export default Popup;


import React, { useEffect, useState, useRef } from "react";
import confetti from "canvas-confetti";
import { PATH } from "../../redux/constants/Constants";
import { getInaugration, inaugration } from "../../services/UserLogin.service";
import { useSelector } from "react-redux";

const Popup = ({ onClose }) => {
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const [showFireworks, setShowFireworks] = useState(false);
  const audioRef = useRef(null);
  const confettiRef = useRef(null);
  const [inaugrationData, setInaugrationData] = useState(null);

  const handleInaugration = () => {
    const data = { status: false };
    setShowFireworks(true);

    inaugration(token, data).then((res) => {
      // console.log(res);
    });
  };

  useEffect(() => {
    getInaugration(token).then((res) => {
      // console.log(res.data);
      setInaugrationData(res.data);
    });

    if (showFireworks) {
      const audio = new Audio(`${PATH}/assets/img/bamboosa/bamboosa-audio.mp3`);
      audioRef.current = audio;

      audio.play().catch((error) =>
        console.error("Audio playback failed:", error)
      );

      const confettiInstance = confetti.create(confettiRef.current, {
        resize: true,
        useWorker: true,
      });

      const duration = 15 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      const randomInRange = (min, max) => Math.random() * (max - min) + min;

      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          clearInterval(interval);
          return;
        }

        const particleCount = 80 * (timeLeft / duration);

        confettiInstance({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        });
        confettiInstance({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        });

        const drawRibbon = (originX, originY, angle, spread, drift, gravity) => {
          confettiInstance({
            particleCount: 10,
            angle: angle,
            spread: spread,
            origin: { x: originX, y: originY },
            colors: ["#bb0000", "#ffffff"],
            shapes: ["rect"],
            scalar: 1.5,
            startVelocity: 30,
            ticks: 200,
            gravity: gravity,
            drift: drift,
            disableForReducedMotion: true,
          });
        };

        drawRibbon(0, 0.5, 60, 55, 0.5, 0.5);
        drawRibbon(1, 0.5, 120, 55, -0.5, 0.5);
      }, 250);

      return () => {
        clearInterval(interval);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
        confettiInstance.reset();
      };
    }
  }, [showFireworks]);

  useEffect(() => {
    if (showFireworks) {
      const timer = setTimeout(handleClose, 17000);
      return () => clearTimeout(timer);
    }
  }, [showFireworks]);

  const startFireworks = () => {
    setShowFireworks(true);
  };

  const handleClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    onClose();
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
        <div style={styles.videoContainer}>
       
          <video
            playsInline
            autoPlay
            loop
            muted
            // poster={`${PATH}/assets/img/bamboosa/bamboosa-frame.jpg`}
            poster={`https://storage.googleapis.com/restaurant60-prod-media/bamboosa-frame.jpg`}
            style={styles.video}
          >
            <source
              // src={`${PATH}/assets/img/bamboosa/bamboosa-video.mp4`}
              src={`https://storage.googleapis.com/restaurant60-prod-media/bamboosa-video.mp4`}
              type="video/mp4"
            />
            <img
                    // src={`${PATH}/assets/img/bamboosa/bamboosa-frame.jpg`}
                    src={`https://storage.googleapis.com/restaurant60-prod-media/bamboosa-frame.jpg`}
                    alt="dummy"
                  />
          </video>
        </div>
        {!showFireworks && (
          <div style={styles.buttonContainer}>
            <button
              id="fireworks"
              onClick={handleInaugration}
              style={styles.ribbonButton}
            >
              Start Celebration!
            </button>
          </div>
        )}
        <canvas
          ref={confettiRef}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999999999999999,
  },
  popup: {
    backgroundColor: "#fff",
    padding: "5px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
    width: "95%", // Adjust popup width
    maxWidth: "1150px", // Max width to prevent it from getting too large
  },
  videoContainer: {
    position: "relative",
    paddingBottom: "56.25%", // 16:9 aspect ratio
    height: 0,
    overflow: "hidden",
    width: "100%",
    borderRadius: "5px",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover", // Ensures the video covers the container while maintaining aspect ratio
  },
  buttonContainer: {
    position: "relative",
  },
  ribbonButton: {
    position: "absolute",
    bottom: 15,
    right: "50%",
    transform: "translateX(50%)", // Center button horizontally within the container
    color: "white",
    backgroundColor: "red",
    border: "none",
    padding: "10px 20px",
    fontSize: "18px",
    cursor: "pointer",
    zIndex: 1,
    outline: "none",
  },
};

export default Popup;


