import axios from "axios";
import { RESTAURANT_ID, BASE_URL } from "../redux/constants/Constants";

export const cartAvailability = async (token, customer_id) => {
    const url = `${BASE_URL}/cart/?customer_id=${customer_id}&restaurant=${RESTAURANT_ID}`;
    const config = {
      headers: {
        // "action": "cart",
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
        // user_id: customer_id.toString(), 
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const createCart = async (token, data) => {
    const url = `${BASE_URL}/cart/`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        throw error;
      }
    }
  };

  export const addToCart = async (token, data) => {
    const url = `${BASE_URL}/v2/cart-item-post/`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };

    console.log(token, data)
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        throw error;
      }
    }
  };

export const getCartItems = async (token, cartId) => {
  const url = `${BASE_URL}/v2/cart-items-x/?cart_id=${cartId}`;
  console.log(token, url, cartId)
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  };

  try {
    const response = await axios.get(url, config);
    return response;
  } catch(error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.log(error);
      // throw error;
    }
  }
}

export const updateCartItems = async(token, data, cartItemId) => {
  const url = `${BASE_URL}/v2/cart-item-post/${cartItemId}/`;
  const config = {
    headers : {
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    }
  }

  try {
    const response = await axios.put(url, data, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
} 

export const deleteCartItem = async(token, cartItemId) => {
 const url = `${BASE_URL}/cart-item-x/${cartItemId}/`;
 const config = {
    headers : {
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    }
  }

  try {
    const response = await axios.delete(url, config);
    return response;
  } catch (error) {
    if (
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      console.log("Unauthorized Access.", error);
    } else {
      console.error(error);
      throw error;
    }
  }
}