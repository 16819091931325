import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ReturnPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const aboutUs = useSelector((state) => state?.status?.restaurant?.about_us);
  const address = useSelector((state) => state?.status?.restaurant?.address);
  const city = useSelector((state) => state?.status?.restaurant?.city);
  const state = useSelector((state) => state?.status?.restaurant?.state);
  const country = useSelector((state) => state?.status?.restaurant?.country);
  const zip = useSelector((state) => state?.status?.restaurant?.zip);
  const email = useSelector((state) => state?.status?.restaurant?.support_email);
  const phone = useSelector(
    (state) => state?.status?.restaurant?.support_phone_number
  );
  const working_hours = useSelector(
    (state) => state?.status?.restaurant?.working_hours
  );

  const newAddress =
    address + ", " + city + ", " + state + ", " + country + ", " + zip;
  // console.log(newAddress);

  return (
    <div style={{ minHeight: "800px" }}>
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
      />

      <div>
        <div className="about-us-wrap">
          <div
            style={{ fontWeight: 900 }}
            className="d-flex justify-content-center"
          >
            <h2 className="mb-4">Return Policy</h2>
          </div>
          <h6 style={{ fontWeight: "600", fontSize: "17px" }} className="my-2">
            Welcome to BambooSA!
          </h6>
          <p>
            Thank you for shopping at BambooSA! We want to ensure your
            satisfaction with every purchase, which is why we offer a
            comprehensive return policy to address any concerns you may have.
          </p>

          <br />
          <h5>1. 7-Day Return Policy</h5>
          <p>
            We offer a 7-day return policy, allowing you to request a return
            within 7 days of receiving your item. To be eligible for a return,
            your item must meet the following criteria:
          </p>
          <p>
            <ul>
              <li>
                1. It must be in the same condition as when you received it.
              </li>
              <li>2. It should be unworn or unused, with all tags attached.</li>
              <li>3. The item must be in its original packaging.</li>
              <li>4. You must provide the receipt or proof of purchase.</li>
            </ul>
          </p>

          <br />
          <h5>2. Initiating a Return</h5>
          <p>
            To start a return, please contact us at{" "}
            <a href={`mailto:${email}`}>{email}</a>. Kindly note that returns
            should be sent to the following address:
          </p>
          <p class="address">{newAddress}</p>
          <p>
            Please arrange for the return shipment as we do not provide return
            pickups.
          </p>

          <br />
          <h5>3. Return Process</h5>
          <p>
            Upon acceptance of your return, we will either exchange the item for
            another product of your choice or refund the amount to your original
            payment method. Items returned without prior authorization will not
            be accepted.
          </p>

          <br />
          <h5>4. Damages and Issues</h5>
          <p>
            We kindly request you to inspect your order upon delivery. If you
            notice any defects, damages, or receive the wrong item, please
            contact us immediately. We will assess the issue and take
            appropriate measures to resolve it.
          </p>

          <br />
          <h5>5. Exchanges</h5>
          <p>
            Should you wish to exchange an item, the quickest method is to
            return the original item following our return procedure. Once the
            return is approved, you can proceed to make a separate purchase for
            the desired item.
          </p>

          <br />
          <h5>6. Refunds</h5>
          <p>
            Once we receive and inspect your return, we will notify you of the
            approval status. If approved, refunds will be processed
            automatically to your original payment method within 10 business
            days. Please note that the time taken for the refund to reflect in
            your account may vary depending on your bank or credit card company.
            If more than 15 business days have passed since the approval of your
            return, please reach out to us at{" "}
            <a href={`mailto:${email}`}>{email}</a>.
          </p>

          <p>
            We are committed to providing you with a hassle-free shopping
            experience, and our dedicated customer support team is always
            available to assist you with any return-related queries or concerns.
          </p>
        </div>
      </div>
    </div>
  );
}
