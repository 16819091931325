import axios from "axios";
import { RESTAURANT_ID, BASE_URL } from "../redux/constants/Constants";

  export const getSubCategory = async (token) => {
    const url = `${BASE_URL}/v2/category/?restaurant_id=${RESTAURANT_ID}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const getAllProducts = async (token, page) => {
    const url = `${BASE_URL}/v2/catalog/?page=${page}&restaurant_id=${RESTAURANT_ID}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const getProductsByColorSize = async (token, product_id) => {
    const url = `${BASE_URL}/get-product-size-color/?type=All&product_id=${product_id}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  };

  export const getProductSpecifications = async(token, product_id) => {
    const url = `${BASE_URL}/product-specification/?product_id=${product_id}&type=Specification`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:  `token ${token}`,
      }
    };

    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const checkPincode = async(token, pincode) => {
    const url = `${BASE_URL}/delivery-location/?restaurant_id=${RESTAURANT_ID}&zip_code=${pincode}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:  `token ${token}`,
      }
    };

    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const getSearchProducts = async(token, searchKey) => {
    console.log(searchKey);
    const url = `${BASE_URL}/graphql/`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:  `token ${token}`,
      }
    };

    const query = `query productSearch ($token: String, $productName: String,$restaurantId:Int, $first: Int, $skip: Int) {
      productSearch (token: $token, productName : $productName, restaurantId :$restaurantId, first: $first, skip: $skip ) {
        productId
        productName
        productUrl
        category { 
          categoryId  
          category
          categoryUrl  
        }
        restaurant { 
          restaurantId 
          address
        }  
      }
    }`

    const variables = {
      token: '0o6jcui8mfhmp56we69kcmu5rkejtock',
      productName: searchKey,
      restaurantId: RESTAURANT_ID,
      first: 20,
      skip: 0
    }
    console.log(query);
    try {
      const response = await axios.post(url, {
        query: query,
        variables: variables
      }, config);
      console.log(response.data)
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const filterProducts = async(token, data) => {
    const url = `${BASE_URL}/product-filter/?size=${data.size}&brand=Bamboosa&min_price=${data.minPrice}&max_price=${data.maxPrice}&color=${data.color}&type=ECOMMERCE&restaurant_id=${RESTAURANT_ID}`;
    console.log(url);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:  `token ${token}`,
      }
    };

    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  export const getAllColors = async(token) => {
    const url = `${BASE_URL}/get-product-size-color/?type=ALL&company_id=${RESTAURANT_ID}`;
    console.log(url);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization:  `token ${token}`,
      }
    };

    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.error(error);
        throw error;
      }
    }
  }

  // Rating

  export const addUserProductRating = async (token, data) => {
    const url = `${BASE_URL}/product-rating/`;
  
    console.log(token, data);
  
    // const formData = new FormData();
  
    // Object.keys(data).forEach((key) => {
    //   if (key === "image" && Array.isArray(data[key]) && data[key].length > 0) {
    //     data[key].forEach((file, index) => {
    //       if (file instanceof File) {
    //         formData.append(key, file, file.name);
    //       }
    //     });
    //   } else {
    //     // Append other data
    //     formData.append(key, data[key]);
    //   }
    // });
  
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.post(url, data, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        throw error;
      }
    }
  };

  export const updateUserProductRating = async (token, data, reviewID) => {
    const url = `${BASE_URL}/product-rating/${reviewID}/`;
  
    console.log(token, data);
  
    const formData = new FormData();
  
    Object.keys(data).forEach((key) => {
      if (key === "image" && Array.isArray(data[key]) && data[key].length > 0 && data[key] !== null) {
        data[key].forEach((file, index) => {
          if (file instanceof File) {
            formData.append(key, file, file.name);
          }
        });
      } else if ((key === "image" && typeof data[key] === 'string') || (key === "image" && data[key] === null)) {

      } else {
        // Append other data
        formData.append(key, data[key]);
      }
    });

    console.log(formData)
  
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.put(url, formData, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        throw error;
      }
    }
  };

  export const getReviewByColorId = async (token, color_name, product_id) => {
    const url = `${BASE_URL}/product-rating/?color_name=${color_name}&product_id=${product_id}`;

    console.log(color_name, product_id)
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `token ${token}`,
      },
    };
  
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      if (
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500
      ) {
        console.log("Unauthorized Access.", error);
      } else {
        console.log(error);
        // throw error;
      }
    }
  };
  

