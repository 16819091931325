import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  changePasswordAPI,
  getProfileDetails,
} from "../../../services/UserLogin.service";

export default function ChangePassword(props) {
  const actTab = props.activeTab;
  const [profileData, setProfileData] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [valid, setValid] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPasswordErrors, setNewPasswordErrors] = useState([]);
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const customerId = useSelector((state) => state?.user?.user?.token?.id);

  useEffect(() => {
    window.scrollTo(0, 0);

    fetchAPIs();
  }, []);

  const fetchAPIs = () => {
    //Get Profile data
    getProfileDetails(token, customerId).then((res) => {
      setProfileData(res?.data?.results[0]);
    });
  };

  const {
    register: register2,
    handleSubmit: handleSubmitChangePassword,
    setError,
    setValue,
    clearErrors,
    watch,
    reset,
    formState: { errors: error2 },
  } = useForm();

  // const newPassword1 = watch("newPassword", "");
  const confirmPassword = watch("confirmPassword", "");
  // let currentPassword = watch("confirmPassword", "");

  useEffect(() => {
    register2("confirmPassword", {
      validate: (value) =>
        value === newPassword || "New Password & Confirm Password do not match",
    });
  }, [newPassword, register2]);

  const changePassword = (item) => {
    // console.log(item);
    // console.log(profileData.customer.username);

    const data = {
      username: profileData?.customer?.username,
      old_password: item.currentPassword,
      new_password1: item.newPassword,
      new_password2: item.confirmPassword,
    };
    // console.log(data);
    if (valid) {
      changePasswordAPI(token, data).then((res) => {
        // console.log(res?.data);
        if (res?.data?.status === "error") {
          toast.error(res?.data?.message, {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "changePasswors",
          });
        } else {
          toast.success("Your password has been changed successfully!", {
            autoClose: 1500,
            style: {
              backgroundColor: "black",
              color: "white",
            },
            containerId: "changePasswors",
          });
          reset();
        }
      });
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setNewPassword(value);
    // console.log("currentPassword ---> ", currentPassword);
    // Validate the password
    const errors = [];

    // At least 8 characters
    if (value) {
      if (currentPassword === value) {
        errors.push("Current Password & New Password could not be same");
      }

      if (value.length < 8) {
        errors.push("Password must contain at least 8 characters");
      }

      // At least one lowercase letter
      if (!/[a-z]/.test(value)) {
        errors.push("one lowercase letter");
      }

      // At least one uppercase letter
      if (!/[A-Z]/.test(value)) {
        errors.push("one uppercase letter");
      }

      // At least one number
      if (!/\d/.test(value)) {
        errors.push("one number");
      }

      // At least one special character
      if (!/[!@#$%^&*()_+\[\]{};:'"\|,.<>\/?]/.test(value)) {
        errors.push("one special character");
      }
    } else {
      errors.push("Enter New Password");
    }

    setValid(errors.length === 0);
    setNewPasswordErrors(errors);
  };

  return (
    <div
      style={{ minHeight: "800px" }}
      className="tab-content"
      id="myaccountContent"
    >
      <ToastContainer
        position="top-center"
        className="mt-4 ToastContainer"
        limit={1}
        containerId="changePasswors"
      />

      <div
        className={`tab-pane fade ${
          actTab == "changePasswordTab" ? "show active" : ""
        }`}
        id="address-edit"
        role="tabpanel"
        aria-labelledby="address-edit-tab"
      >
        <div className="myaccount-content">
          <h3>Change Password</h3>
          <form
            action="#"
            method="post"
            onSubmit={handleSubmitChangePassword(changePassword)}
          >
            <fieldset>
              <div className="single-input-item mx-2">
                <label className="required">Current Password</label>
                <input
                  type="password"
                  placeholder="Current Password"
                  {...register2("currentPassword", {
                    required: "Enter Current Password",
                    minLength: {
                      value: 8, // Minimum length required
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                  maxLength={20}
                  onChange={(item) => setCurrentPassword(item.target.value)}
                />
                {error2.currentPassword && (
                  <p className="error">{error2.currentPassword.message}</p>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-input-item mx-2">
                    <label className="required">New Password</label>
                    <input
                      type="password"
                      placeholder="New Password"
                      {...register2("newPassword", {
                        required: "Enter New Password",
                        // minLength: {
                        //   value: 8, // Minimum length required
                        //   message: "Password must be at least 8 characters long",
                        // },
                      })}
                      maxLength={20}
                      onChange={handlePasswordChange}
                    />
                    {error2.newPassword && newPassword === "" && (
                      <p className="error">{error2.newPassword.message}</p>
                    )}

                    {!valid && (
                      <ul>
                        {newPasswordErrors.map((error, index) => (
                          <li className="p-error" key={index}>
                            {error}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-input-item mx-2">
                    <label className="required">Confirm Password</label>
                    <input
                      type="password"
                      placeholder="Confirm Password"
                      {...register2("confirmPassword", {
                        required: "Enter Confirm Password",
                        minLength: {
                          value: 8, // Minimum length required
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                      maxLength={20}
                    />
                    {error2.confirmPassword && (
                      <p className="error">{error2.confirmPassword.message}</p>
                    )}
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="single-input-item mt-5 mx-2">
              <button type="submit" className="check-btn sqr-btn ">
                Change Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
