import { useDispatch, useSelector } from "react-redux";
import Router from "./Router";
import { useEffect, useState } from "react";
import { checkFirstVisit, hidePopup, initSession } from "./redux/actions/Actions";
import Popup from "./components/features/Popup";
import { getInaugration } from "./services/UserLogin.service";

function App() {
  const token = useSelector((state) => state?.status?.restaurant?.Token);

  const [inaugrationStatus, setInaugrationStatus] = useState(true);
  // const dispatch = useDispatch();
  // const showPopupState = useSelector((state) => state.popup.showPopup);

  // useEffect(() => {
  //   dispatch(checkFirstVisit());
  // }, [dispatch]);

  // const handleClosePopup = () => {
  //   dispatch(hidePopup());
  // };

  const dispatch = useDispatch();
  // const showPopupState = useSelector((state) => state.popup.showPopup);

  // useEffect(() => {
  //   dispatch(initSession());
  // }, [dispatch]);

  const handleClosePopup = () => {
    // dispatch(hidePopup());
    // setShowPopup(false)

    setInaugrationStatus(true)
  };

  const [showPopup, setShowPopup] = useState(false);
  const popupShown = sessionStorage.getItem('popupShown');

  useEffect(() => {

    token && getInaugration(token).then((res) => {
      // console.log(res)
      setInaugrationStatus(res?.data?.status)
    })

    // Check if the popup has been shown in the current session
    const popupShown = sessionStorage.getItem('popupShown');

    if (!popupShown) {
      // If not, show the popup
      setShowPopup(true);
      // Mark the popup as shown in sessionStorage
      sessionStorage.setItem('popupShown', 'true');
    }
  }, []);

  return (
    <div>
      <Router />
      {/* {showPopupState && <Popup onClose={handleClosePopup} />} */}
      {/* {showPopup && <Popup onClose={handleClosePopup} />} */}

      {/* {showPopup && (
        <div className="popup">
          <h2>Special Offer!</h2>
          <p>Enjoy a special discount just for visiting!</p>
          <button onClick={() => setShowPopup(false)}>Close</button>
        </div>
      )} */}

{!inaugrationStatus && <Popup onClose={handleClosePopup} />}

    </div>
  );
}

export default App;
