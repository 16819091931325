export const RESTAURANT_DETAILS = "RESTAURANT_DETAILS";
export const GET_USER_TOKEN = "GET_USER_TOKEN";
export const LOGOUT_SESSION = "LOGOUT_SESSION";
export const USER_CART_DETAILS = "USER_CART_DETAILS";
export const USER_CART_ITEMS = "USER_CART_ITEMS";
export const USER_PRODUCTS = "USER_PRODUCTS";
export const REMEMBER_ME = "REMEMBER_ME";
export const FORGET_ME = "FORGET_ME";
export const ADD_ITEM_TO_PRELOGIN_CART = 'ADD_ITEM_TO_PRELOGIN_CART';
export const CLEAR_PRE_LOGIN_CART = "CLEAR_PRE_LOGIN_CART"
export const REMOVE_PRE_LOGIN_CART_ITEM = "REMOVE_PRE_LOGIN_CART_ITEM"
export const SHOW_POPUP = 'SHOW_POPUP';
export const HIDE_POPUP = 'HIDE_POPUP';
export const CHECK_FIRST_VISIT = 'CHECK_FIRST_VISIT';
export const INIT_SESSION = 'INIT_SESSION';

// export const BASE_URL = "https://restaurant60-be-dev-xtpocjmkpa-uw.a.run.app"  // GitHub Dev

// export const BASE_URL = "https://instaapp-dev-xtpocjmkpa-uw.a.run.app" // Bit Buccket Dev
// export const RESTAURANT_ID = "1"; // Dev

export const BASE_URL = "https://instaapp-prod-xtpocjmkpa-uw.a.run.app" // Bit Buccket Prod
export const RESTAURANT_ID = "494"; // Prod

// export const PATH = "/prod"
export const PATH = ""


