import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubCategory,
  getAllProducts,
  getProductsByColorSize,
} from "../../services/Products.service";
import { Link, useNavigate } from "react-router-dom";
import {
  addToCart,
  cartAvailability,
  createCart,
  getCartItems,
} from "../../services/Cart.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PATH, RESTAURANT_ID } from "../../redux/constants/Constants";
import {
  restaurantDetailsAction,
  userCartDetails,
  userCartItems,
} from "../../redux/actions/Actions";
import { getRestaurantDetails } from "../../services/UserLogin.service";

export default function Home() {
  const token = useSelector((state) => state?.status?.restaurant?.Token);
  const currency_symbol = useSelector(
    (state) => state?.status?.restaurant?.currency_symbol
  );
  const userData = useSelector((state) => state?.user?.user?.token);
  const dispatch = useDispatch();
  const cartId = useSelector(
    (state) => state?.cart?.cart?.results && state?.cart?.cart?.results[0]?.id
  );

  // console.log(cartId);

  const [subCategory, setSubCategory] = useState(null);
  const [subCategoryFilter, setSubCategoryFilter] = useState(null);
  const [products, setProducts] = useState(null);
  const [productsByColor, setProductsByColor] = useState(null);
  const [activeProductsArray, setActiveProductArray] = useState(null);
  const [firstProductData, setFirstProductData] = useState(null);
  const [firstProductData2, setFirstProductData2] = useState(null);

  const [colourObjects, setColourObjects] = useState(null);
  const navigate = useNavigate();
  const activeProducts = {};

  const fetchAPIs = (token) => {
    cartId &&
      getCartItems(token, cartId).then((res) => {
        res && dispatch(userCartItems(res?.data));

        // console.log(res?.data);
      });

    getRestaurantDetails().then((res) => {
      if (res?.data) {
        // setRestaurantDetails(res?.data);
        dispatch(restaurantDetailsAction(res?.data));
        // console.log(res?.data);

        getSubCategory(res?.data.Token).then((res) => {
          const activeProducts = {};
          // console.log(res);
          res?.data?.forEach((product) => {
            if (product.master_category_status === true) {
              activeProducts[product.sequence] = product;
            }
          });
          // console.log(activeProducts);
          const subCategoryArray = Object.values(activeProducts);
          // console.log(subCategoryArray);

          setSubCategory(subCategoryArray);
          setSubCategoryFilter(subCategoryArray);
          // console.log(subCategoryArray);
        });
      }
    });

    // getSubCategory(token).then((res) => {
    //   const activeProducts = {};
    //   res?.data?.forEach((product) => {
    //     if (product.master_category_status === true) {
    //       activeProducts[product.sequence] = product;
    //     }
    //   });
    //   // console.log(activeProducts)
    //   const subCategoryArray = Object.values(activeProducts);
    //   setSubCategory(subCategoryArray);
    //   console.log(subCategoryArray);
    // });
    getAllProducts(token, "1").then((res) => {
      setProducts(res?.data?.results);
      // console.log(res?.data.results);
      const activeProducts = {};
      res?.data?.results?.forEach((product) => {
        if (
          product.status === "ACTIVE" &&
          product.in_stock === true &&
          product.category_id === subCategory?.category
        ) {
          activeProducts[product.product_id] = product;
        }
      });
      const array = Object.values(activeProducts);
      setActiveProductArray(array);
      // console.log(array);
      const firstProduct = array?.[0];
      setFirstProductData2(firstProduct);
      if (firstProduct) {
        getProductsByColorSize(token, firstProduct.product_id).then((res) => {
          // console.log(res?.data);
          setFirstProductData(res?.data);
          const colorObjArray = res?.data.color_obj_dict; // Assuming this is the array containing color objects
          setColourObjects(colorObjArray);
          // const sizeSProducts = colorObjArray.filter(
          //   (colorObj) => colorObj.size.name === "S"
          // );
          // console.log(sizeSProducts);
          // setProductsByColor(sizeSProducts);
          const uniqueColors = {};

          // Iterate through each color object
          colorObjArray.forEach((colorObj) => {
            const { color, size, quantity } = colorObj;
            const [colorName, colorCode] = color.split(","); // Split color string into name and code

            // Filtering out sizes other than S, M, L, XL
            if (
              size.name === "S" ||
              size.name === "M" ||
              size.name === "L" ||
              size.name === "XL"
            ) {
              if (
                !(colorName in uniqueColors) ||
                (size.name === "S" && quantity > 0) ||
                (size.name === "M" &&
                  uniqueColors[colorName].size.name !== "S" &&
                  quantity > 0) ||
                (size.name === "L" &&
                  uniqueColors[colorName].size.name !== "S" &&
                  uniqueColors[colorName].size.name !== "M" &&
                  quantity > 0) ||
                (size.name === "XL" &&
                  uniqueColors[colorName].size.name !== "S" &&
                  uniqueColors[colorName].size.name !== "M" &&
                  uniqueColors[colorName].size.name !== "L" &&
                  quantity > 0)
              ) {
                uniqueColors[colorName] = colorObj;
              }
            }
          });

          // Convert the uniqueColors object into an array
          const uniqueColorsArray = Object.values(uniqueColors);

          // Output the unique colors and their minimum sizes
          uniqueColorsArray.forEach((color) => {
            // console.log(
            //   `Color: ${color.color}, Code: ${color.code}, Size: ${color.size.name}`
            // );
          });

          const colorArray = Object.values(uniqueColors);
          // console.log(colorArray);

          setProductsByColor(colorArray);
        });
      }
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    getRestaurantDetails().then((res) => {
      // console.log(res?.data);
      if (res?.data) {
        // setRestaurantDetails(res?.data);

        fetchAPIs(res?.data.Token);
        dispatch(restaurantDetailsAction(res?.data));
        // console.log(res?.data);
        cartId &&
          getCartItems(res?.data.Token, cartId).then((res) => {
            res && dispatch(userCartItems(res?.data));

            // console.log(res?.data);
          });
      }
    });
  }, []);

  const handleProducts = async (item) => {
    // console.log(item);
    navigate(`/products`, { state: { subCategory: item } });
  };

  // console.log(activeProductsArray)

  // console.log(productsByColor);

  const productList = (item) => {
    setFirstProductData2(item);
    // console.log(item);
    getProductsByColorSize(token, item.product_id).then((res) => {
      // console.log(res?.data);
      setFirstProductData(res?.data);
      const colorObjArray = res?.data.color_obj_dict; // Assuming this is the array containing color objects
      setColourObjects(colorObjArray);
      // const sizeSProducts = colorObjArray.filter(
      //   (colorObj) => colorObj.size.name === "S"
      // );

      // console.log(sizeSProducts);
      // setProductsByColor(sizeSProducts);

      const uniqueColors = {};

      // Iterate through each color object
      colorObjArray.forEach((colorObj) => {
        const { color, size, quantity } = colorObj;
        const [colorName, colorCode] = color.split(","); // Split color string into name and code

        // Filtering out sizes other than S, M, L, XL
        if (
          size.name === "S" ||
          size.name === "M" ||
          size.name === "L" ||
          size.name === "XL"
        ) {
          if (
            !(colorName in uniqueColors) ||
            (size.name === "S" && quantity > 0) ||
            (size.name === "M" &&
              uniqueColors[colorName].size.name !== "S" &&
              quantity > 0) ||
            (size.name === "L" &&
              uniqueColors[colorName].size.name !== "S" &&
              uniqueColors[colorName].size.name !== "M" &&
              quantity > 0) ||
            (size.name === "XL" &&
              uniqueColors[colorName].size.name !== "S" &&
              uniqueColors[colorName].size.name !== "M" &&
              uniqueColors[colorName].size.name !== "L" &&
              quantity > 0)
          ) {
            uniqueColors[colorName] = colorObj;
          }
        }
      });

      // Convert the uniqueColors object into an array
      const uniqueColorsArray = Object.values(uniqueColors);

      // Output the unique colors and their minimum sizes
      uniqueColorsArray.forEach((color) => {
        // console.log(
        //   `Color: ${color.color}, Code: ${color.code}, Size: ${color.size.name}`
        // );
      });

      const colorArray = Object.values(uniqueColors);
      // console.log(colorArray);

      setProductsByColor(colorArray);
    });
  };

  const handleProductDetails = (item) => {
    // console.log(subCategory);
    // console.log(item);
    navigate(`/product-details`, {
      state: {
        firstProductData: firstProductData2,
        colorObj: colourObjects,
        productsByColor: item,
        subCategory: subCategory,
      },
    });
  };

  const handleAddToCart = (item) => {
    if (userData) {
      cartAvailability(token, userData.id).then((res) => {
        // console.log(res?.data);
        // console.log(res?.data.results?.length < 1);
        if (res?.data.results?.length < 1) {
          const data = {
            customer_id: userData.id,
            restaurant: RESTAURANT_ID,
          };

          createCart(token, data).then((res) => {
            // console.log(res?.data);

            const data = {
              price: item.price,
              quantity: 1.0,
              cart_id: res?.data.id,
              product_id: item.product,
              color_id: item.color_id,
            };

            // console.log(data);

            addToCart(token, data).then(async (res) => {
              const resData = await res?.data;

              // console.log(resData);

              dispatch(userCartItems(resData));

              toast.success("Product added to the cart!", {
                autoClose: 1500,
                style: {
                  backgroundColor: "black",
                  color: "white",
                },
                containerId: "home",
              });
            });

            cartAvailability(token, userData.id).then((res) => {
              // console.log(res?.data);
              dispatch(userCartDetails(res?.data));
            });
          });
        } else {
          const data = {
            price: item.price,
            quantity: 1.0,
            cart_id: res?.data.results[0]?.id,
            product_id: item.product,
            color_id: item.color_id,
          };

          addToCart(token, data).then(async (res) => {
            const resData = await res?.data;

            // console.log(resData);

            dispatch(userCartItems(resData));

            toast.success("Product added to the cart!", {
              autoClose: 1500,
              style: {
                backgroundColor: "black",
                color: "white",
              },
              containerId: "home",
            });
          });

          // console.log(data);

          dispatch(userCartDetails(res?.data));
          // console.log(res?.data);
        }
      });
    } else {
      toast.warning("Please log in to continue!", {
        autoClose: 1500,
        style: {
          backgroundColor: "black",
          color: "white",
        },
        containerId: "home",
      });

      setTimeout(() => {
        navigate(`/login`);
      }, 3000);
    }

    // console.log(item);
  };

  // const handleShopNow = () => {
  //   window.scrollTo(0, document.body.scrollHeight - 1150);
  // };

  // Create a ref for the div you want to scroll to
  const scrollToRef = useRef(null);

  // Function to scroll to the div
  // const handleScrollToDiv = () => {
  //   scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
  // };

  // Function to scroll to the div with an offset
  const handleScrollToDiv = () => {
    const offset = 200; // Adjust this value as needed
    const elementPosition = scrollToRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    setSubCategoryFilter(subCategory);
  };

  const handleSubCategory = (item) => {
    // console.log(item);

    // const filterByCategoryFor = (subCategory, categoryFor) => {
    //   // return subCategory.filter((item) => item.category_for === categoryFor);
    //   return subCategory.filter((item) => categoryFor.test(item.category_for.toString().toLowerCase()));
    // };

    const filterByCategoryFor = (subCategory, keywords) => {
      return subCategory.filter((item) =>
        keywords.includes(item.category_for.toLowerCase())
      );
    };

    const result = subCategory && filterByCategoryFor(subCategory, item);

    // console.log(result);
    setSubCategoryFilter(result);

    const offset = 100; // Adjust this value as needed
    const elementPosition = scrollToRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  // console.log(subCategory);
  const currentYear = new Date().getFullYear();

  return (
    <div style={{ minHeight: "800px" }}>
      {/* <!-- slider area start --> */}
      <div className="hero-area">
        <ToastContainer
          position="top-center"
          className="mt-4 ToastContainer"
          limit={1}
          containerId="home"
        />
        <div className="hero-slider-active slider-arrow-style slick-dot-style slick-initialized slick-slider slick-dotted">
          <div className="slick-list draggable">
            <div className="hero-single-slide hero-overlay hero-overlay-black">
              {/* <div
                className="hero-slider-item hero-slider-item__style-2 bg-img"
                data-bg="assets/img/slider/slide-3.jpg"
                style={{
                  backgroundImage: 'url("assets/img/slider/slide-3.jpg")',
                }}
              >
                <div className="container">
                  <div className="slider-content">
                    <h3>Trending 2019</h3>
                    <h1>
                      Cover Up!
                      <br />
                      Winter Is Coming
                    </h1>
                    <Link
                      to="javascript:void(0)"
                      className="slider-btn slider-btn__white"
                      onClick={handleScrollToDiv}
                    >
                      shop now
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="hero-slider-item hero-slider-item__style-2 bg-img">
                {/* <video playsInline autoPlay muted loop className="bg-video__content">
                  <source
                    src={`${PATH}/assets/img/bamboosa/homepage.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video> */}
                <video
                  playsInline
                  className="bg-video__content"
                  autoPlay
                  loop
                  muted
                  poster="https://storage.googleapis.com/restaurant60-prod-media/homepagePoster.webp"
                >
                  <source
                    src="https://storage.googleapis.com/restaurant60-prod-media/homepage.mp4"
                    type="video/mp4"
                  />
                  <img
                    src="https://storage.googleapis.com/restaurant60-prod-media/homepagePoster.webp"
                    alt="dummy"
                  />
                </video>
                <div className="container trending-style">
                  <div className="slider-content">
                    <h1>Trending {currentYear}</h1>
                    {/* <h1>
                      Cover Up!
                      <br />
                      Winter Is Coming
                    </h1> */}
                    <Link
                      to="javascript:void(0)"
                      className="slider-btn slider-btn__white"
                      onClick={handleScrollToDiv}
                    >
                      shop now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- slider area end --> */}

      {/* <!-- banner statistics start --> */}

      {/* <div className="banner-statistic-area mt-30">
        <div className="container custom-container">
          <div className="row mtn-30 justify-content-center">
            <div className="col-md-6 col-lg-5">
              <div className="img-container img-full fix mt-30">
                <Link to="javascript:void(0)">
                  <img src="assets/img/banner/cms_2.1.jpg" alt="banner image" />
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="img-container img-full fix mt-30">
                <Link to="javascript:void(0)">
                  <img src="assets/img/banner/cms_2.2.jpg" alt="banner image" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="banner-statistic-area section-padding pb-0">
        <div className="container">
          <div className="row mtn-30">
            <div className="col-md-6">
              {/* <div className="img-container img-full fix mt-30 me-3">
                <Link to="javascript:void(0)">
                  <img
                    // src={`${PATH}/assets/img/banner/cms_2.4.jpg`}
                    src={`${PATH}/assets/img/bamboosa/women-poster.webp`}
                    alt="banner"
                    onClick={() =>
                      handleSubCategory(["women", "women's", "womens"])
                    }
                  />
                </Link>
                
              </div> */}
              <div
                className="img-container img-full fix mt-30 me-3"
                style={{ position: "relative" }}
              >
                <Link to="javascript:void(0)">
                  <img
                    src={`${PATH}/assets/img/bamboosa/women-banner.webp`}
                    alt="banner"
                    onClick={() =>
                      handleSubCategory(["women", "women's", "womens"])
                    }
                    style={{ width: "100%", height: "auto" }}
                  />
                  {/* Text overlay */}
                  {/* <div className="img-text-women">
      For Women
    </div> */}
                  {/* Button overlay */}
                  {/* <button
                    onClick={() =>
                      handleSubCategory(["women", "women's", "womens"])
                    }
                    className="img-button-women"
                  >
                    Shop Now
                  </button> */}
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-container img-full fix mt-30 ms-3">
                <Link to="javascript:void(0)">
                  <img
                    // src={`${PATH}/assets/img/banner/cms_2.5.jpg`}
                    src={`${PATH}/assets/img/bamboosa/men-banner.webp`}
                    alt="banner"
                    onClick={() => handleSubCategory(["men", "men's", "mens"])}
                  />
                  {/* Button overlay */}
                  {/* <button
                    onClick={() =>
                      handleSubCategory(["women", "women's", "womens"])
                    }
                    className="img-button-women"
                  >
                    Shop Now
                  </button> */}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- banner statistics end --> */}

      {/* <!-- featured product area start --> */}
      <div ref={scrollToRef}></div>
      <div className="new-product section-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <p>Available Now</p>
                <h2>Top Categories</h2>
              </div>
            </div>
          </div>
          <div className="row mtn-30">
            <div className="col-12 px-4">
              {/* px-5 */}
              {
                <div className="product-carousel-10 slick-row-12 row">
                  {subCategoryFilter && subCategoryFilter.length > 0 ? (
                    subCategoryFilter.map((item, i) =>
                      item.master_category_status === true ? (
                        <div className="col-sm-3" key={i}>
                          <div
                            className="product-item mt-30 d-flex justify-content-left p-3 zoom"
                            style={{ overflow: "auto" }}
                          >
                            <div>
                              <div
                                className="product-thumb"
                                // style={{ maxWidth: "386px", minWidth: "305px" }}
                                onClick={() => handleProducts(item)}
                              >
                                <Link to="javascript:void(0)">
                                  <img
                                    src={item.category_url}
                                    alt="product-img"
                                    className="h-100 w-100 zoom"
                                  />
                                </Link>
                              </div>
                              <div className="product-description text-center">
                                <div className="product-name">
                                  <h3 onClick={() => handleProducts(item)}>
                                    <Link to="javascript:void(0)">
                                      {item.category_for + " " + item.category}
                                    </Link>
                                  </h3>
                                </div>
                                {/* <div className="price-box">
                            <span className="regular-price">$90.00</span>
                            <span className="old-price">
                              <del>$100.00</del>
                            </span>
                          </div>  */}
                                <div
                                  className="product-btn"
                                  onClick={() => handleProducts(item)}
                                >
                                  <Link to="javascript:void(0)">
                                    <b>Shop Now</b>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div></div>
                      )
                    )
                  ) : (
                    <div className="d-flex justify-content-center mt-3">
                      Products are Loading...
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {/* <!-- featured product area end --> */}

      {/* <!-- banner statistics start --> */}
      {/* <div className="banner-statistics-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div
                className="banner-content text-center bg-img"
                data-bg="assets/img/banner/banner-bg.jpg"
                style={{
                  backgroundImage: 'url("assets/img/banner/banner-bg.jpg")',
                }}
              >
                <h2 className="banner-title">Every Piece Comes With</h2>
                <h5 className="banner-subtitle">
                  All Products Sale Up To <span>40% Off</span>
                </h5>
                <Link to="javascript:void(0)" className="shop-btn" onClick={handleScrollToDiv}>
                  SHOP NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- banner statistics end --> */}

      {/* <!-- feature category area start --> */}
      <div className="feature-category-vertical section-padding">
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center pb-44">
                <p>Available Now</p>
                <h2>popular items</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <div className="tab-menu-vertical vertical-bdr mx-3">
                <ul
                  className="nav flex-column"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {activeProductsArray &&
                    activeProductsArray.map((item, i) => (
                      <div key={i}>
                        <li
                          role="presentation"
                          onClick={() => productList(item)}
                        >
                          <button
                          // id="tab_one_tab"
                          // data-bs-toggle="tab"
                          // data-bs-target="#tab_one"
                          // type="button"
                          // role="tab"
                          // aria-controls="tab_one"
                          // aria-selected="false"
                          >
                            {item.master_category + " " + item.category_name}
                          </button>
                        </li>
                      </div>
                    ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-9">
              <div className="tab-content">
                <div
                  className="tab-pane fade show active"
                  id="tab_two"
                  role="tabpanel"
                  aria-labelledby="tab_two_tab"
                >
                  <div
                    className="feature-category-carousel slick-row-12 d-flex"
                    style={{ overflow: "auto", scrollbarWidth: "thin" }}
                  >
                    {productsByColor &&
                      productsByColor.map((item, i) => (
                        <div className="product-item ms-3" key={i}>
                          <div
                            className="product-thumb"
                            style={{ width: "260px" }} // height: "340px",
                            onClick={() => handleProductDetails(item)}
                          >
                            <Link to="javascript:void(0)">
                              <img
                                src={item.image_url.split("@")[0]}
                                alt="product-img"
                                className="h-100 w-100 zoom"
                              />
                            </Link>
                          </div>
                          <div className="product-description text-center">
                            <div
                              className="product-name"
                              onClick={() => handleProductDetails(item)}
                            >
                              <h3>
                                <Link to="javascript:void(0)">
                                  {firstProductData.product_name} (
                                  {item.color.split(",")[0]})
                                </Link>
                              </h3>
                            </div>
                            <div className="price-box">
                              <span className="regular-price">
                                {currency_symbol === "₹" ? "₹" : "$"}
                                {(item.price * 1).toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                              {item.price >= item.MRP ? null : (
                                <span className="old-price ms-2">
                                  <del>
                                    {currency_symbol === "₹" ? "₹" : "$"}
                                    {(item.MRP * 1).toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                                  </del>
                                </span>
                              )}
                              {item.price >= item.MRP ? null : (
                                <span
                                  className="regular-price ms-2"
                                  style={{ color: "red", fontSize: "12px" }}
                                >
                                  {(
                                    ((item.MRP - item.price) / item.MRP) *
                                    100
                                  ).toFixed(2)}
                                  % off
                                </span>
                              )}
                            </div>
                            {/* {item.quantity > 0 ? (
                              <div
                                className="product-btn"
                                onClick={() => handleAddToCart(item)}
                              >
                                <Link to="javascript:void(0)">add to cart</Link>
                              </div>
                            ) : (
                              <div className="product-btn">
                                <Link style={{ background: "grey" }} to="javascript:void(0)">
                                  add to cart
                                </Link>
                              </div>
                            )} */}
                            <div
                              className="product-btn mb-3"
                              onClick={() => handleProductDetails(item)}
                            >
                              <Link to="javascript:void(0)">view details</Link>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- feature category area end --> */}
    </div>
  );
}
